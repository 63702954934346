﻿a, .cd-primary-nav > li > a:hover, .cd-primary-nav > li > a.selected, .cd-primary-nav .cd-secondary-nav > li > a, .cd-primary-nav .cd-secondary-nav a:hover, .cd-primary-nav .cd-nav-gallery .cd-nav-item h3, .cd-primary-nav .cd-nav-icons .cd-nav-item h3, .has-children > a:hover:before, .has-children > a:hover:after, .go-back a:hover:before, .go-back a:hover:after {
  color: #3498db;
}

.cd-overlay {
  background-color: #3498dbcc;
}

.cd-main-header {
  border-bottom: 3px solid #002d62;
}

.cd-logo {
  width: 150px;
  top: 5px;
}

.cd-primary-nav > li > a.selected {
  box-shadow: inset 0 -2px #002d62;
}

.cd-main-content {
  background: #f5f5f5;
  margin-bottom: 15px;
  margin-right: 15px;
}

body {
  background-color: #f5f5f5;
  font-family: Segoe UI, Tahoma, Geneva, Verdana, sans-serif;
}

.in {
  float: left;
  color: #3498db;
  font-size: 24px;
  font-style: italic;
}

.sync {
  float: left;
  color: #002d62;
  font-size: 24px;
}

@media only screen and (min-width: 1170px) {
  .cd-logo {
    width: 250px;
    top: 5px;
  }

  .in, .sync {
    font-size: 48px;
  }

  .cd-primary-nav .cd-nav-icons .cd-nav-item {
    height: 105px;
  }
}

.cd-primary-nav a, .cd-primary-nav ul a {
  white-space: normal;
}

.ie .cd-logo {
  width: 250px;
  top: 5px;
}

.ie .in, .sync {
  font-size: 48px;
}

.ie .cd-primary-nav .cd-nav-icons .cd-nav-item {
  height: 105px;
}

/*# sourceMappingURL=index.753510f5.css.map */
