﻿a, .cd-primary-nav > li > a:hover, .cd-primary-nav > li > a.selected,
.cd-primary-nav .cd-secondary-nav > li > a, .cd-primary-nav .cd-secondary-nav a:hover,
.cd-primary-nav .cd-nav-gallery .cd-nav-item h3,
.cd-primary-nav .cd-nav-icons .cd-nav-item h3,
.has-children > a:hover::before, .has-children > a:hover::after, .go-back a:hover::before, .go-back a:hover::after {
    color: #3498db;
}

.cd-overlay {
    background-color: rgba(52, 152, 219, 0.8);
}

.cd-main-header {
    border-bottom: 3px solid rgba(0,45,98,1);
}

.cd-logo {
    width: 150px;
    top: 5px;
}

.cd-primary-nav > li > a.selected {
    box-shadow: inset 0 -2px 0 rgba(0,45,98,1);
}

.cd-main-content {
  background: #f5f5f5;
  /*background: url(http://www.insync-pharmacy.co.uk/wp-content/uploads/2015/07/pills.jpg);
  background-size: 100%;*/
  margin-right: 15px;
  margin-bottom: 15px;
}

body {
    background-color: #f5f5f5;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.in {
    font-size: 24px;
    float: left;
    font-style: italic;
    color: #3498db;
}

.sync {
    font-size: 24px;
    float: left;
    color: rgba(0,45,98,1);
}

@media only screen and (min-width: 1170px) {

    .cd-logo {
        top: 5px;
        width: 250px;
    }

    .in, .sync {
        font-size: 48px;
    }

    .cd-primary-nav .cd-nav-icons .cd-nav-item {
        height: 105px;
    }
}

.cd-primary-nav a, .cd-primary-nav ul a {
    white-space: normal;
}

/*IE 8*/
.ie .cd-logo {
    top: 5px;
    width: 250px;
}

.ie .in, .sync {
    font-size: 48px;
}

.ie .cd-primary-nav .cd-nav-icons .cd-nav-item {
    height: 105px;
}